.appbar-container {
  width: 100%;
  padding: 0;
  margin-right: 0px;
}
/* .row {
  margin: 0;
} */
.app-bar-wrp {
  width: 100%;
  background: #0a2e69;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-evenly;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
}

.app-bar-center {
  width: 100%;
  font-size: 12px;
}

.app-bar-center span {
  text-transform: capitalize;
}

.app-bar-left {
  width: 100%;
}

.app-bar-left img {
  width: 150px;
}
.page-title {
  margin-left: 15px;
  font-weight: bolder;
}
.app-bar-right {
  width: 100%;
  padding-right: 20px;
}

.user-info:hover .profile-options {
  display: block;
  transition: 0.5s;
  z-index: 1;
}

.img-wrp {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.img-wrp img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.user-name {
  display: inline-block;
  font-weight: bolder;
  font-size: 12px;
  vertical-align: middle;
}
.icon {
  margin-left: 7px;
}
.profile-options {
  position: absolute;
  width: 165px;
  padding: 5px 0px 0px;
  top: 45px;
  display: none;
  left: 40px;
  background: #fff;
}
.options-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.option-link {
  display: inline-block;
  font-size: 14px;
  width: 100%;
  padding: 5px 10px 7px;
  text-decoration: none;
  outline: 0;
  color: #fff;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.zero-border {
  border: none;
}
.badges-wrp {
  float: right;
  width: auto;
  padding: 5px 15px;
  border-left: 1px solid #e2f0fa;
  border-right: 1px solid #e2f0fa;
  margin-right: 10px;
}
.badg {
  display: inline-block;
  width: auto;
  margin: 0px 5px;
  padding: 10px;
}
.badge-icon {
  display: inline-block;
  width: auto;
  margin-right: 10px;
  vertical-align: middle;
}
.badge-icon svg {
  vertical-align: sub;
}
.count {
  display: inline-block;
  font-size: 11px;
  font-weight: bolder;
  vertical-align: text-bottom;
}
/* Input Field Style */
.search {
  width: 50px;
  position: relative;
  transition: all 0.5s ease;
  float: right;
  margin-right: 5px;
  padding: 5px 0px;
}
.search.open {
  width: 300px;
}
.search.open:before {
  height: 60px;
  margin: 20px 0 20px 30px;
  position: absolute;
}
.search-box {
  width: 100%;
  height: 40px;
  border: 1px solid #dedede;
  visibility: hidden;
}
.search-box:focus {
  outline: none;
}
.search.open .search-box {
  visibility: visible;
  padding: 0px 10px;
}
.search.open .search-button {
  left: unset;
  right: 0;
}
.search-button {
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
}
.search-icon {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 3px solid #00fede;
  display: block;
  position: relative;
  margin-left: 5px;
  transition: all 0.5s ease;
}
.search-icon:before {
  content: "";
  width: 3px;
  height: 15px;
  position: absolute;
  right: -2px;
  top: 30px;
  display: block;
  background-color: #00fede;
  transform: rotate(-45deg);
  transition: all 0.5s ease;
}
.search-icon:after {
  content: "";
  width: 3px;
  height: 15px;
  position: absolute;
  right: -12px;
  top: 40px;
  display: block;
  background-color: #00fede;
  transform: rotate(-45deg);
  transition: all 0.5s ease;
}
.open .search-icon {
  margin: 0;
  width: 60px;
  height: 60px;
  border-radius: 60px;
}
.open .search-icon:before {
  transform: rotate(52deg);
  right: 22px;
  top: 23px;
  height: 18px;
}
.open .search-icon:after {
  transform: rotate(-230deg);
  right: 22px;
  top: 13px;
  height: 18px;
}
.tooltip-inner {
  text-align: center !important;
  background-color: #fff !important;
  color: #000000 !important;
  font-size: 15px;
  max-width: 270px !important;
  z-index: 111111 !important;
}

.tooltip-inner {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.tooltip.show {
  opacity: 1 !important;
}
