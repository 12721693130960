.App {
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.hideIcon {
  display: none !important;
}

.MuiListItem-button:hover {
  color: #0a2e69 !important;
}

.persistantDrawer {
  display: none !important;
}

.error-message {
  /* position: absolute; */
  color: #ad380e !important;
  font-size: 13px !important;
  top: 70% !important;
}

.paper {
  background-color: transparent !important;
}

input:disabled {
  color: #808080 !important;
  cursor: not-allowed;
}

.MuiToolbar-gutters {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media only screen and (max-width: 565px) {
  .row-progress-bar {
    min-height: 60vh !important;
  }

  .row-progress-bar > div {
    padding: 15px !important;
  }
}

@media only screen and (max-width: 730px) {
  /* .avgbaord {
    display: none !important;
  } */

  .right-wrapper {
    max-width: 100% !important;
  }

  .analysis-main-wrapper {
    min-width: min-content !important;
  }
}

@media only screen and (max-width: 450px) {
  .data-items span {
    margin: 0 0 50px 0 !important;
  }

  .data-items .value {
    font-size: 12px !important;
  }

  .right-wrapper .top h4 {
    padding-left: 12px !important;
    font-size: 1.1em !important;
  }

  .data-wrapper .data-items {
    padding: 20px !important;
  }
}

@media only screen and (max-width: 500px) {
  .form-card-body > div {
    margin: 0 !important;
  }

  .main-input-group {
    padding: 0 !important;
  }

  .arrowHeadSidebar {
    display: none !important;
  }

  .root-container {
    margin: 40px 0px 0px 0px !important;
  }

  .hideIcon {
    display: inline !important;
  }

  .submit-button-wrapper {
    margin-left: 0px !important;
  }

  .main-page {
    padding: 0px !important;
  }

  .app-bar-center {
    display: none !important;
  }

  .page-link-button {
    min-width: 15% !important;
  }
  .input-field input {
    margin-left: 0 !important;
    width: 100% !important;
    padding-right: 10px !important;
  }

  .contact-us-page .input-field input {
    margin-left: 0;
    width: 100%;
    padding-right: 10px;
  }

  .input-field .prefix {
    display: none;
  }
  .user-details-view {
    padding: 20% 0px 0px 0px !important ;
  }
  .user-account-view {
    padding: 20% 0px 0px 0px !important ;
  }

  .input-field svg {
    display: none;
  }

  .user-details-view .container {
    padding-bottom: 5em !important;
  }
  .user-account-view .container {
    padding-bottom: 5em !important;
  }
  .form-card-body .text-right {
    float: right !important;
  }

  .form-card-body .text-right button {
    margin-right: 0 !important;
  }

  .user-details-view .text-right {
    float: right;
  }

  .footer-content {
    flex-direction: column !important;
    padding: 5px !important;
  }

  .footer-content span {
    font-size: 12px !important;
  }

  .user-details-view .text-right button {
    margin-right: 0 !important;
  }
  .user-account-view .text-right button {
    margin-right: 0 !important;
  }

  .input-field label {
    margin-left: 0 !important;
    left: 0 !important;
  }

  .contact-us-select-field .form-control,
  .contact-us-select-field .form-control:focus {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .details_bg {
    width: 100% !important;
  }

  .share-modal .md-form input {
    width: 100% !important;
    padding-right: 0;
    margin-right: 0px !important;
  }

  .share-modal .md-form label {
    margin-left: 0 !important;
  }

  .loading-bar {
    width: auto !important;

    transform: scale(0.6) !important;
  }

  .edit-view-buttons {
    float: none !important;
  }

  .user-edit-view input {
    padding: 0 !important;
  }
  .export-data-options .option-container {
    flex-direction: column !important;
  }

  .dashboard-header {
    display: inline-block !important;
    margin: 5% !important;
  }

  .add-request {
    background-color: transparent;
    box-shadow: none;
    text-transform: none;
  }
  .add-request:hover {
    background-color: transparent !important;
  }
  .add-request:focus {
    outline: none;
  }

  .paper {
    width: fit-content !important;
  }
  .show_icon {
    display: flex !important;
  }

  .input-icons {
    right: 0 !important;
  }

  .input-icons {
    top: 16px !important;
  }

  .input-icons img {
    width: 12px !important;
  }
}
