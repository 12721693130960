/* Importing custom styles from src/styles foler*/
@import url("./styles/card.css");
@import url("./styles/table.css");

/* Importing font files */
@font-face {
  font-family: "ClanOT-Narrow";
  src: url("./assets/fonts/ClanOT-NarrowNews.woff") format("woff");
}

@font-face {
  font-family: "ClanOT-NarrowThin";
  src: url("./assets/fonts/ClanOT-NarrowThin.woff") format("woff");
}

@font-face {
  font-family: "ClanOT-Narrow Bold";
  src: url("./assets/fonts/ClanOT-NarrowBold.woff") format("woff");
}

@font-face {
  font-family: "ClanOT-Narrow Black";
  src: url("./assets/fonts/ClanOT-NarrowBlack.woff") format("woff");
}

@font-face {
  font-family: "ClanOT-Narrow Medium";
  src: url("./assets/fonts/ClanOT-NarrowMedium.woff") format("woff");
}

@font-face {
  font-family: "ClanOT-Narrow Book";
  src: url("./assets/fonts/ClanOT-NarrowBook.woff") format("woff");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("./assets/fonts/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("./assets/fonts/Poppins-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}

body {
  padding-right: 0 !important;
  margin: 0;
  font-family: "ClanOT-Narrow", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px !important;
}

code {
  font-family: "ClanOT-Narrow Bold", "ClanOT-Narrow", source-code-pro, Menlo,
    Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "ClanOT-Narrow", "ClanOT-Narrow Bold", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 24px !important;
}

table {
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}

.ant-table-thead {
  font-family: "ClanOT-Narrow Bold" !important;
  color: #454343;
  line-height: 15px;
  background-color: #ffffff;
  font-size: 13px;
}

/* Table Listing */
.Main-table-wrapper {
  font-family: "ClanOT-Narrow Bold" !important;
  border-collapse: separate !important;
  border-spacing: 0 1em !important;
  background-color: #f9f9f9;
  box-sizing: border-box;
  border-radius: 6px;
  border: none;
  width: 100% !important;
  overflow-x: visible;
}
.Main-table-wrapper tr {
  background-color: #fff;
}
.Main-table-wrapper thead tr th {
  font-family: Poppins-ExtraLight;
  font-weight: 700 !important;
  font-size: 18px;
  padding: 23px 10px;
  color: #b9b8b8;
  border: none;
  /* border-bottom: 1px solid #173971; */
  box-sizing: border-box;
}

@media (max-width:1200px ) and (min-width: 650px){ 
  .Main-table-wrapper thead tr th {

    font-size: 16px !important;
    padding: 23px 5px !important;
  }
  .Main-table-wrapper tbody tr td {
    font-size: 16px !important;
    padding: 23px 5px !important;
  }
}
.first-table-index {
  text-align: left !important;
  padding-left: 25px !important;
}
.Main-table-wrapper tbody tr td {
  font-family: Poppins-ExtraLight;
  text-align: center;
  color: #173971;
  font-size: 18px;
  font-weight: 700;
  padding: 23px 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  /* border-bottom: 1px solid #173971; */
  box-sizing: border-box;
}
.Main-table-wrapper tbody tr td.first-table-index {
  color: #3cb4e4;
}
.Main-table-wrapper tbody tr td.second-tap {
  color: #545353;
}
.Main-table-wrapper tbody tr td.third-tap {
  color: #78d124;
}
.Main-table-wrapper tbody tr th {
  color: #173971;
  font-size: 14px;
  border: none;
  /* border-bottom: 1px solid #173971 !important; */
  box-sizing: border-box;
}

.Main-table-wrapper .MuiSvgIcon-root,
.filter-list .MuiSvgIcon-root,
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #173971 !important;
}

.Mui-selected {
  background-color: #0676ea26 !important;
  border-radius: 0 !important;
}

.filter-list .MuiList-root {
  padding-right: 0px !important;
}

.filter-list .MuiList-root li {
  font-family: "ClanOT-Narrow";
  color: #173971 !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: transparent !important;
}

.table-header-cell {
  cursor: pointer;
}

.Sorting-icon {
  display: inline-block;
  margin-left: 8px;
  font-size: 13px !important;
}
.Sorting-icon svg {
  vertical-align: middle;
}

.change-row {
  box-sizing: border-box;
  box-shadow: 0px 0px 30px #edeef4;
}

.div-btn span {
  display: inline-block !important;
  vertical-align: text-top !important;
}
.div-btn label {
  display: inline-block !important;
  vertical-align: text-top !important;
}

.Pagination div span,
.Pagination div div {
  font-size: 12px;
  font-family: "clanOT-Narrow";
}

.Pagination p {
  font-family: "clanOT-Narrow";
}

.Pagination {
  color: #173971 !important;
}

.Main-wrapper {
  background: transparent !important;
}

.background-empty {
  background-color: #f5f6fa !important;
}

.Page-Heading {
  font-size: 25px !important;
  margin: 0px 0px 10px !important;
  font-family: "ClanOT-Narrow Bold" !important;
  padding: 10px;
}

/* Search Button & Input Field */

.search-paper {
  display: "flex" !important;
  align-items: "center" !important;
  width: 19% !important;
  margin: 25px 0 25px !important;
  box-shadow: none !important;
  width: 240px !important;
  background: #ffffff;
  border: 1px solid #e2f0fa !important;
  box-sizing: border-box;
  border-radius: 25px !important;
  border-color: #e9e8e7 !important;
}
.search-input {
  float: left;
  width: auto;
}

.search-input :-ms-input-placeholder {
  color: #173971 !important;
}
.search-input input {
  padding: 8px 6px !important;
  font-size: 12px;
}
.search-paper button {
  float: left;
  padding: 8px 6px !important;
}
.search-paper button.Search-Button {
  padding: 8px 6px !important;
  float: right !important;
}

.loading_sec {
  border: 0;
  background: transparent;
  box-shadow: none;
  padding: 0 !important;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.loading_sec .card-body {
  position: relative;
  padding: 0;
  text-align: center;
}

.progress-bar {
  width: 18px;
  -webkit-animation: progress 6s ease-in-out forwards !important;
  animation: width 6s ease-in-out forwards !important;
}
.progress-bar {
  background: -webkit-linear-gradient(
    left,
    #fb5304 0%,
    #7abb15 80%,
    #3eb4e7 100%
  );
}

.progress {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  margin: 0 auto;
  background: #3c3c3c;
}

.loading_sec .card-body .main_progress_img img {
  position: relative;
  z-index: 99999;
  width: 960px;
  height: 187px;
  max-width: none;
}

.signup-modal {
  text-align: center;
  z-index: 1202 !important;
  display: flex !important;
  align-items: center;
}

.signup-modal .modal-content {
  padding: 40px;
  font-size: 13px;
  font-family: "ClanOT-Narrow Medium";
}

.signup-modal-logo {
  width: 15em;
}

.signup-modal .button-groups button {
  margin: 15px;
}

.signup-modal .cross {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.details-modal .modal-content {
  padding: 10px;
}

.submission-page p {
  color: #ffffff;
  font-size: 16px;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer {
  color: #ffffff;
  width: 100%;
  bottom: 0;
}

.footer-content a {
  color: #0a2e69;
}

.footer-content a:hover {
  cursor: pointer;
  text-decoration: none;
}

.add-new-request-button {
  margin-top: 0px !important;
  margin-right: 10px !important;
  border-color: #0a2e69 !important;
  color: #0a2e69;
  background-color: #0a2e69 !important;
}

.add-new-request-button:hover,
.add-new-request-button:focus,
.add-new-request-button:active {
  border-color: #0a2e69 !important;
  color: #ffffff !important;
  background-color: #0a2e69 !important;
}

.add-user-button {
  margin-top: 0px !important;
  margin-left: 10px !important;
  background-color: #0a2e69 !important;
  font-family: "clanOT-Narrow" !important;
}
.bulk-delete-button {
  margin-top: 0px !important;
  margin-left: 10px !important;
}

.user-details-view .back-arrow {
  width: 20px;
  margin-bottom: 10px;
  color: #0a2e69;
}
.user-account-view .back-arrow {
  width: 20px;
  margin-bottom: 10px;
  color: #0a2e69;
}

.details-container {
  right: 0;
  top: 60px;
  max-width: 60% !important;
  height: auto;
}

.details-container * {
  padding: 4px;
}

.details-container .back-arrow {
  width: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  color: #ffffff !important;
}

.details-container label {
  font-weight: bolder;
  font-size: 20px;
}

.details-container button {
  width: 100px !important;
  background-position: 0px !important;
}

.details-icon {
  height: 30px;
}

.confirm-button {
  border: 1px solid black !important;
}

.root-container {
  margin: 60px 0px 0px 70px !important;
}

.add-margin-to-root-container {
  margin: 64px 0px 0px 0px !important;
}
@media (max-width: 960px) and (min-width: 651px) {

  .add-margin-to-root-container {
    margin: 30px 0px 0px 0px !important;
  }
  .root-container {
    margin: 35px 0px 0px 70px !important;
  }
}
@media (max-width: 650px) {

  .add-margin-to-root-container {
    margin: 35px 0px 0px 0px !important;
  }
  .root-container {
    margin: 35px 0px 0px 70px !important;
  }
}
/* User Form */

.User-form-wrp {
  background: #ffffff;
  border: 1px solid #e2f0fa;
  box-sizing: border-box;
  border-radius: 4px !important;
  padding: 15px;
  box-shadow: unset !important;
}

.Form-heading-wrp {
  float: left;
  width: 100%;
  padding: 35px 30px 0px;
}

.Form-heading {
  font-size: 18px;
  line-height: 23px;
  color: #17224e;
  font-weight: 700;
}

.Form-sub-heading {
  font-size: 14px;
  line-height: 21px;
  color: #454343;
  margin-top: 3px;
}

.user-form-wrp {
  float: left !important;
  width: 100% !important;
  padding: 0px 25px 0px !important;
  margin: 20px 0px auto !important;
}

.user-form-wrp .input-field-first {
  margin-top: 0px !important;
}

.user-form-wrp .input-field-first .campaign-name {
  margin-top: 0px !important;
}

.wizard-input-lable {
  font-size: 12px;
  color: #566477 !important;
  margin-bottom: 0px;
}

.campaign-name {
  margin-top: 10px !important;
  width: 100%;
}

.campaign-name input {
  height: 3.4px !important;
  font-size: 12px;
}

.campaign-name div input {
  padding: 19px 10px !important;
  font-size: 12px;
  background-color: #ffffff;
}

.campaign-name div fieldset {
  border: 1px solid #e2f0fa;
  box-sizing: border-box;
  border-radius: 4px;
}

.Select-styling {
  border: 1px solid #e2f0fa !important;
  box-sizing: border-box !important;
  box-shadow: 0px 0px 0px #006ae6 !important;
  border-radius: 4px !important;
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
}

.Select-styling div div span {
  font-size: 12px !important;
}

.Select-styling div {
  padding-left: 5px !important;
}
.Select-styling svg {
  top: 14px;
}

.select-campaign-name {
  width: 50%;
  margin-top: 10px;
  float: left;
}

.select-campaign-name2 {
  width: 100% !important;
  margin-top: 10px;
  float: left;
}

.select-campaign-name > div {
  border: 1px solid #e2f0fa;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 26px 13px 12px;
  font-size: 12px !important;
  background-color: #ffffff;
}
.select-campaign-name svg {
  top: 15px;
  right: 5px;
}
.select-campaign-name:before {
  border-bottom: 0px !important;
}

.select-campaign-name2 div {
  border: 1px solid #e2f0fa;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 26px 13px 12px;
  font-size: 12px !important;
}

.search-paper .search-input > input ::placeholder {
  color: #173971 !important;
  font-family: "ClanOT-Narrow Bold";
}

.dashboard-header {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 10px 0px 10px;
}

.dashboard-header .filter-icon {
  color: #173971 !important;
  cursor: pointer;
}

.dashboard-header-rt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dashboard-header h2 {
  color: #173971;
  font-weight: 800;
  margin: 25px 0 25px !important;
}

.sidebar-list-item .MuiListItemIcon-root {
  color: #ffffff;
}

.sidebar-list-item span {
  font-family: "clanOT-Narrow";
}

.sidebar-list-item:hover .MuiListItemIcon-root {
  color: #173971;
}

.sidebar-list-item:hover .admin {
  fill: #173971;
}

.sidebar-list-item:hover .MuiListItem-button {
  background-color: #ffffff;
}

.details_bg {
  background-image: url(./assets/images/gradient_bg_1.jpg);
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed !important;
  right: 0;
  height: 100%;
  top: 0;
  padding: 0 !important;
  overflow-y: scroll;
  overflow-x: hidden;
  transition-timing-function: ease-in-out;
}

.details_bg .frm_head h4 {
  font-size: 2em !important;
}

.details_bg .card_frm_view {
  margin: 40% 15px 0px 20px !important;
  padding-top: 30px !important;
}

.details_bg .brand-panel ul {
  text-align: left !important;
  list-style: none;
  padding-left: 0 !important;
  font-family: "ClanOT-Narrow Bold";
  min-width: 100% !important;
}

.details_bg .brand-panel ul li {
  font-family: "ClanOT-Narrow Bold";
  color: #1e3c72 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px;
  max-width: 100% !important;
}

.details_bg .brand-panel ul li label {
  overflow: hidden;
  position: inherit;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70%;
  vertical-align: text-top;
}

.details_bg .brand-label {
  font-size: 14px;
  color: #3d4054;
  margin: 10px 0px 0px 2px;
  font-weight: 600;
}

.details_bg .brand-panel {
  border: none;
  padding-left: 0px;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  border-bottom-left-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

.details_bg .brand-panel .MuiExpansionPanelDetails-root {
  padding: 0 !important;
}

.details_bg .brand-panel::before {
  content: none;
}

.details_bg .brand-panel .MuiExpansionPanelSummary-root {
  padding: 0 !important;
  font-family: "ClanOT-Narrow";
  color: #1e3c72 !important;
}

.details_bg .brand-panel .MuiTypography-body1 {
  font-family: "ClanOT-Narrow";
}
.details_bg .close-icon {
  position: absolute;
  right: 18px;
  top: 22%;
  cursor: pointer;
}
.close-icon {
  width: 40px;
  height: 40px;
}
.details_bg .frm_head {
  padding: 2px 5px !important;
  top: -60px !important;
}

.right_sec {
  display: flex;
  justify-content: start;
  margin-left: 5px;
  min-width: max-content;
}

.right_sec button {
  font-family: "Poppins-Medium";
  color: #7b7b7b;
  font-size: 15px;
}

.admin {
  fill: #ffffff;
}

.admin:hover {
  fill: #061c41;
}

.export-data-options span {
  text-transform: capitalize;
  font-family: "clanOT-Narrow";
}

.export-data-options .MuiFormControlLabel-label {
  color: #0a2e69;
}

.export-data-options .option-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.edit-formulas-table tr td {
  font-family: "clanOT-Narrow" !important;
  text-transform: capitalize;
  text-align: center !important;
}

.edit-formulas-table thead tr th {
  font-family: "clanOT-Narrow" !important;
  text-align: center !important;
}
.edit-formulas-table thead tr th img {
  width: 30px !important;
}

.edit-formulas-table input {
  width: 100% !important;
  border: 1px solid black;
  border-radius: 5% !important;
  text-align: center;
}

.modal-tabs .Mui-selected {
  background-color: #061c41 !important;
  color: #ffffff !important;
}

.modal-tabs button {
  font-family: "clanOT-Narrow" !important;
  border: none !important;
  color: #061c41;
}

.model-tabs .MuiTabs-indicator {
  color: #ffff !important;
}

.averages {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.averages span {
  font-size: 16px;
  color: #0a2e69;
}
