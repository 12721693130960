.main-container-landing {
  background-color: #f8f8f8;
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  @media (max-width: 705px) {
    height: 84.5vh;
  }
  @media (max-width: 560px) {
    height: 82vh;
  }
  @media (max-width: 500px) {
    height: 85.2vh;
  }
  @media (max-width: 375px) {
    height: 82.1vh;
  }
  @media (max-width: 360px) {
    height: 73.9vh;
  }

  .main-container .row {
    margin: 0 !important;
  }

  .landing-main-row {
    flex-direction: row;
    padding-top: 5px;
  }
  .button-Div {
    position: absolute;
    bottom: 5.5%;
    left: 30%;
  }

  .main-page {
    font-family: "ClanOT-NarrowNews";

    letter-spacing: 0.02em;
    margin: 5px 20px;
    text-transform: uppercase;
    padding: 30px 30px 0 25px;
    font-weight: 500;
  }
  .show-image {
    display: none !important;
    margin-top: 30px;
  }

  .main-page-content {
    padding: 0px 60px 0 20px;
    @media (max-width: 820px) {
      padding: 0 60px 0 20px;
    }
    @media (min-width: 820px) and (max-width: 1205px) {
      padding: 0 0px 0 0px;
    }
  }

  .main-page {
    flex: 0 0 58.333333%;
  }
  .brand-image {
    margin-left: 3rem !important;
  }
  .show-image {
    display: block !important;
    margin-top: 128px;
  }

  .header {
    top: 0;
    right: 0;
    left: 0;
    position: absolute;
  }
  .main-header {
    font-size: 32px !important;
    font-family: "Poppins-ExtraBold" !important;
    color: black;
  }
  .main-paragraph {
    font-size: 20px;
    color: #545353;
    font-family: "Poppins-Regular" !important;
  }
  ul {
    list-style: none;
  }
  ul li {
    display: flex;
    align-items: center;
  }
  ul li::before {
    content: "\2022";
    color: #78d124;
    margin-left: -38px;
    font-size: 32px;
    line-height: 22px;
  }
  .liText {
    color: #545353;
    font-size: 14px;
    font-family: "Poppins-Bold" !important;
    margin-left: 10px;
  }
  .button-title {
    font-family: "Poppins-Medium";
    color: #21201f;
    font-size: 25px;
  }
  .input-group {
    overflow: hidden;
    padding-right: 2%;
    font-size: 18px;
    border-color: transparent;
    border-left-color: #fff;
    border-left-width: 0.1px;
    padding-left: 10px;
    flex: 1 1 auto;
    width: 1% !important;
    margin-right: 10px;
    font-family: "Poppins-Medium";
  }

  .input-group:focus {
    outline: none !important;
  }

  .main-input-group {
    display: flex;
    flex-direction: row;
    vertical-align: top;
    padding: 0px 30px 0 5px;
    max-width: 500px;
    margin-top: 10px;
    background-color: white;

    border-radius: 3px;

    border: 1px solid #ddd;
    height: 45px;
  }
  .main-container-landing .submit-button-wrapper {
    max-width: 500px;
  }

  .input-icon {
    border: 1px;
  }

  .submit-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end !important;
    max-width: 500px !important;
  }

  .buttons,
  .buttons:hover,
  .buttons:active,
  .buttons:focus {
    border: 1px solid white !important;
    border-radius: 5px;
    color: #fff !important;
    box-shadow: none !important;
    font-size: 22px;
    font-family: "Poppins-Medium";
    margin: 20px 0 0;
    line-height: 39px;
  }

  .image-div img {
    max-width: 100%;
    height: 515px;
    margin-left: 3rem !important;
    object-fit: contain;
    margin: 0 auto;
    text-align: center;
  }
  .page-link-button,
  .page-link-button:hover,
  .page-link-button:active,
  .page-link-button:focus {
    position: absolute;
    right: 65px;
    margin: 10px 65px 0 0;
    z-index: 9999 !important;

    background-color: #fb260e;
    color: white;
    border-radius: 0;
    border: 1px solid #fb260e;
    border-radius: 5px;

    font-size: 15px;
    min-width: 125px;
    min-height: 40px;
  }

  .main-container .general-info-dropdown {
    right: 0;
    position: absolute;
    margin-right: 15px;
    margin-top: 10px;
    font-size: 1em;
    background: transparent !important;
    z-index: 9999;
  }

  .main-container .general-info-dropdown button,
  .main-container .general-info-dropdown button:focus,
  .main-container .general-info-dropdown button:hover,
  .main-container .general-info-dropdown button:active {
    background-color: #ffff !important;
    border: 1px solid white;
    color: #000000 !important;
    border-radius: 0;
    box-shadow: none;
    min-width: 100px;
  }

  .dropdown-menu {
    background-color: transparent !important;
    left: -40px !important;
    padding: 0%;
  }

  .main-container .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #000000 !important;
  }

  .logo img {
    padding: 10px 0px 0 20px;
    height: 70px;
    width: 379px;
  }
}
//------------------------------------ Media Query-----------------------------------------------//

@media (min-height: 969px) {
  .image-div img {
  }
}

@media (max-width: 539px) {
  .landing-main-row {
    flex-direction: column;
    padding-top: 33px !important;
  }
  .image-div img {
    margin-left: 0rem !important;
  }
  .button-Div {
    left: 30% !important;
  }
}
@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .column1 {
    margin-top: 2rem;
  }
  .show-image {
    margin-top: 2rem !important; //30px
  }
  .image-div img {
    max-width: none !important;
    height: 430px !important;
  }
  .button-Div {
    bottom: 0.5% !important;
    left: 16% !important;
  }
  .landing-main-row {
    padding-top: 122px !important;
  }
}
@media only screen and (min-width: 760px) and (max-width: 835px) {
  .column1 {
    margin-top: 2rem;
  }
  .show-image {
    margin-top: 2rem !important; //30px
  }
  .image-div img {
    margin-left: 0rem !important;
    max-width: none !important;
    height: 380px !important;
  }
  .button-Div {
    bottom: 0.5% !important;
    left: 1% !important;
  }
  .main-container-landing .main-header {
    font-size: 23px !important;
  }
  .main-paragraph {
    font-size: 14px !important;
  }
  .liText {
    font-size: 12px !important;
  }
  .button-title {
    font-size: 16px !important;
  }
  .landing-main-row {
    padding-top: 100px !important;
  }
  .column-2 {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 540px) and (max-width: 1000px) {
  .column-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-div img {
    margin-left: 0rem !important;
  }
}
@media only screen and (min-width: 540px) and (max-width: 760px) {
  .column1 {
    margin-top: 3rem;
  }
  .button-Div {
    position: absolute;
    bottom: 5.5%;
    left: 30%;
  }
  .image-div img {
    margin-left: 0rem !important;
  }
  .landing-main-row {
    padding-top: 122px !important;
  }
}
.main-page-content {
  margin-top: 120px !important;
  @media (max-width: 1000px) {
    margin-top: -65px !important;
  }
}

@media (max-width: 540px) {
  .landing-main-row {
    padding-top: 11rem !important;
  }
}
@media only screen and (min-width: 1270px) and (max-width: 1300px) {
  .image-div img {
    height: 511px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1270px) {
  .image-div img {
    height: 551px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1479px) {
  .main-page {
    flex: 0 0 58.333333%;
    margin: 0px 0px;
  }

  .show-image {
    display: block !important;
    margin-top: 128px; //30px
  }
}
@media only screen and (min-width: 1370px) and (max-width: 1950px) {
  .main-container-landing .submit-button-wrapper {
    max-width: 500px !important;
  }
}
@media only screen and (min-width: 1430px) and (max-width: 1480px) {
  .image-div img {
    height: 433px !important;
  }
}
@media only screen and (min-width: 1480px) and (max-width: 1650px) {
  .liText {
    color: #545353;
    font-size: 17px !important;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1650px) and (max-width: 1950px) {
  .liText {
    color: #545353;
    font-size: 20px !important;
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1480px) and (max-width: 1950px) {
  .main-header {
    font-size: 48px !important;
  }
  .main-paragraph {
    font-size: 36px;
  }
  .options-list {
    ul li::before {
      content: "\2022";
      color: #78d124;
      margin-left: -1px;
      font-size: 2px;
      line-height: 45px !important;
    }
  }

  .image-div img {
    height: 486px !important;
  }

  .show-image {
    display: block !important;
    margin-top: 128px;
  }

  .page-link-button,
  .page-link-button:hover,
  .page-link-button:active,
  .page-link-button:focus {
    position: absolute;
    right: 65px;
    margin: 10px 65px 0 0;
    z-index: 9999 !important;
    background-color: #fb260e;
    color: white;
    border-radius: 0;
    border: 1px solid #fb260e;
    border-radius: 5px;
    font-size: 15px;
    min-width: 125px;
    min-height: 40px;
  }

  .main-container .general-info-dropdown {
    right: 0;
    position: absolute;
    margin-right: 15px;
    margin-top: 10px;
    font-size: 1em;
    background: transparent !important;
    z-index: 9999;
  }

  .main-container .general-info-dropdown button,
  .main-container .general-info-dropdown button:focus,
  .main-container .general-info-dropdown button:hover,
  .main-container .general-info-dropdown button:active {
    background-color: #ffff !important;
    border: 1px solid white;
    color: #000000 !important;
    border-radius: 0;
    box-shadow: none;
    min-width: 100px;
  }

  .dropdown-menu {
    background-color: transparent !important;
    left: -40px !important;
    padding: 0%;
  }

  .main-container .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #000000 !important;
  }

  .logo img {
    padding: 10px 0px 0 20px;
    height: 70px;
    width: 379px;
  }
}

@media only screen and (min-width: 1950px) and (max-width: 7000px) {
  .main-header {
    font-size: 55px !important;
  }
  .main-container-landing .main-header {
    font-size: 55px !important;
  }
  .main-container-landing .main-paragraph {
    font-size: 35px;
  }
  .main-container-landing .liText {
    font-size: 30px;
    line-height: 1;
    margin-left: 10px;
  }
  .main-container-landing .button-title {
    font-size: 40px;
  }
  .main-container-landing .main-input-group {
    padding: 0px 30px 0 5px;
    max-width: 632px;
    margin-top: 15px;
    border-radius: 3px;
    border: 1px solid #ddd;
    height: 45px;
  }
  .main-container-landing .submit-button-wrapper {
    max-width: 632px;
  }
  .main-container-landing .buttons,
  .main-container-landing .buttons:hover,
  .main-container-landing .buttons:active,
  .main-container-landing .buttons:focus {
    font-size: 35px;
    height: 80px;
    margin: 20px 0 0;
  }
  .main-paragraph {
    font-size: 30px;
  }

  .button-Div {
    bottom: 5.5%;
    left: 30%;
  }
  .image-div img {
    min-height: 800px !important;
  }
}
