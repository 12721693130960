.loader-main {
  height: 100vh;
  background-color: #fff;
  color: white;
}

.logo img {
  height: 70px;
  width: 379px;
  margin-bottom: 10px;
}

.main-header {
  font-size: 37px !important;
  font-family: "Poppins-ExtraBold" !important;
  color: black;
}
.main-paragraph {
  margin-top: 20px !important;
  font-size: 22px;
  color: #545353;
  font-family: "Poppins-Regular" !important;
}

.loader-main h2 {
  font-family: "ClanOT-Narrow Bold";
}

.linear-progress-bar {
  height: 6px !important;
  background-color: white !important;
  border-radius: 100px;
}

.MuiLinearProgress-barColorPrimary {
  background: -webkit-linear-gradient(
    left,
    #fb5304 0%,
    #7abb15 80%,
    #3eb4e7 100%
  );
  transition: transform 1s linear !important;
}

.social-images {
  position: absolute;
  width: 37%;
  left: 33.2%;
  bottom: 44.6%;
  z-index: 0;
  top: 10px;
}

.social-images span {
  margin: 30px;
  height: 45px;
}

.test {
  background: -webkit-linear-gradient(
    left,
    #fb5304 0%,
    #7abb15 80%,
    #3eb4e7 100%
  );
  animation: mymove 8s;
  animation-timing-function: ease-in-out;
  height: 8em;
}

.ptest {
  margin: 0 auto;
  height: 8em;
  position: absolute;
  top: 10px;
  width: 100%;
}

@keyframes mymove {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.animation-bar {
  animation-name: progess;
  animation-duration: 10s;
  background: -webkit-linear-gradient(
    left,
    #fb5304 0%,
    #7abb15 80%,
    #3eb4e7 100%
  );
  transition: transform 2.4s linear !important;
}

@keyframes progress {
  0% {
    width: 1px;
  }

  100% {
    width: 100px;
  }
}

.loading-bar {
  display: flex;
  height: 100%;
  width: 46em;
  margin: 10px auto;
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
}
